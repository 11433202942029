@import '../../../assets/scss/colors.scss';

.page {
  margin: 40px 32px 0 32px;
  overflow-y: scroll;
  height: 100%;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headline {
    font-weight: 700;
    font-size: 20px;
    line-height: 27.28px;
  }
}
.head2 {
  display: flex;
  align-items: center;
  .headline2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27.28px;
    margin-left: 12px;
  }
}

.lh27 {
  // line-height: 27.28px;
  margin-left: 16px;
  margin-top: 1.5px;
}
.table-count {
  margin-top: 20px;
  margin-bottom: 24px;
}
.skuBtn {
  background: #cee4ff;
  color: #0067e2;
  text-align: center;
  padding: 10px 16px;
  height: 40px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  border-width: 0;
  margin-right: 24px;
  cursor: pointer;
}

.customerIssuesBtn {
  background-color: white;
  color: #0067e2;
  text-align: center;
  padding: 10px 16px;  
  height: 40px;
  border-radius: 5px;
  border: 1px solid #0067e2;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-right: 24px;
}
.button-switch {
  display: flex;
  margin: 16px 0;
  cursor: pointer;

  background-color: var(--fill-light-gray-bg);
  width: fit-content;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  .btn-selected {
    background: #cee4ff;
    color: #0067e2;
    text-align: center;
    padding: 9px 30px;
    // height: 40px;
    border-radius: 8px;
    font-weight: bold;
    // font-size: 16px;
    border-width: 0;
  }

  .btn-unselected {
    padding: 9px 30px;
    color: var(--fill-grey-dark2);
  }
}
.button-switch-main {
  display: flex;
  margin: 16px 0;
  cursor: pointer;
  border: 1px solid #CEE4FF;
  width: fit-content;
  border-radius: 88px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  .btn-selected-main {
    background: #F2F8FF;
    color: #0067e2;
    text-align: center;
    padding: 9px 32px;
    border-radius: 88px;
    font-weight: 700;
    border-width: 0;
    border: 1px solid #0067E2;
  }

  .btn-unselected-main {
    padding: 9px 32px;
    color: var(--fill-grey-dark2);
    border-radius: 88px;
    font-weight: 400;
  }
}
.form-row {
  .form-column {
    margin-bottom: 32px;
    max-width: 100%;
    flex: 0 0 50%;
  }
  .form-button {
    display: flex;
    justify-content: space-around;
    margin-top: 2.5rem;
  }
}

.name {
  font-size: 10.5px;
}
.cross {
  text-align: center;
  padding: 10px 20px 0px;
}

.flex {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.edit {
  color: var(--fill-primary-blue);
  font-size: small;
  font-weight: bold;
  // width: 6.5rem;
  text-align: center;
  border: 1px solid var(--fill-light-blue);
  border-radius: 6px;
  padding: 8px 16px;
  margin-right: 24px;
  cursor: pointer;
}
.editBtn {
  display: flex;
  color: var(--fill-primary-blue);
  background-color: var(--fill-blue-light3);
  border: 1px solid var(--fill-light-blue);
  height: 32px;
  width: 32px;
  font-size: small;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 24px;
  cursor: pointer;
}
.saveDisabled {
  height: 40px;
  width: 100%;
  border: none;
  border-radius: 8px;
  padding: 12px;
  background: var(--fill-blue-300);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.save {
  height: 40px;
  border: none;
  border-radius: 8px;
  padding: 12px;
  background: var(--fill-primary-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.inventoryTable {
  thead {
    tr {
      th {
        background: var(--fill-light-gray-bg) !important;
        font-weight: 700;
      }
    }
  }
  margin-bottom: 100px;
  margin-top: 2px;
}
.clear {
  width: 183px;
  height: 40px !important;
  font-weight: 700 !important;
  color: var(--fill-primary-blue) !important;
  border: none !important;
}
.apply {
  width: 183px;
  height: 40px !important;
  background-color: var(--fill-primary-blue) !important;
  border-radius: 8px !important;
  color: white !important;
}
.uploadTxt {
  font-size: 12px;
}
.downloadContainer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.downloadAnchor {
  text-decoration: underline;
  color: var(--fill-primary-blue);
  width: '40%';
}
.inventoryNote {
  margin-top: 16px;
  padding: 8px;
  color: var(--fill-chetwode-blue);
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  display: flex;
  gap: 4px;
  background-color: var(--fill-yellow-light);
}
.viewStyles {
  color: var(--fill-primary-blue);
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.suppliers-modal {
  .ant-modal-content {
    border-radius: 16px !important;
  }
  .ant-modal-header {
    border-bottom-color: white;
    padding-top: 24px;
    border-radius: 16px 16px 0px 0px;
  }
  .ant-modal-close-x {
    padding-top: 6px;
  }
  .ant-modal-body {
    padding-top: 18px;
    padding-bottom: 12px;
  }
  .ant-modal-footer {
    border-top-color: white;
    padding-bottom: 26px;
  }
  .ant-modal-title {
    font-size: 20px;
    font-weight: 700;
  }
}
.dot {
  background: var(--fill-grey-dark3);
  border-radius: 50%;
  height: 4px;
  margin: 0 8px;
  width: 4px;
}
.specification-column {
  display: flex;
  align-items: center;
}

.pointer {
  cursor: pointer;
}
.pop-box {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  min-width: 10rem;

  .popValue {
    font-weight: 600;
    padding-right: 4px;
  }
}

.seperator {
  border-bottom: dashed;
  border-color: gray;
  border-width: 1px;
  margin: 8px 0px;
}
.imgBox {
  border-radius: 8px;
  width: 48px;
  height: 48px;
  object-fit: cover;
  position: absolute;
}
.imgBoxModal {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.productName {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.requirement {
  flex-wrap: wrap;
  gap: 8px;
}
.weightSpecification {
  border-radius: 16px;
  border: 1px solid #d4d4d4;
  background: #ffffff;
  display: flex;
}
.toggleBox {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
}
.unselected {
  background: #ffffff;
  color: #626870;
}
.selected {
  background: #f2f8ff;
  color: #0067e2;
  border: 1px solid #0067e2;
}
.filter-box {
  border-radius: 6px;
  border: 1px solid var(--Primary-Blue-1, #cee4ff);
  background: var(--Neutral-White, #fff);
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
  margin-left: 24px;
  margin-bottom: 64px;
  cursor: pointer;
}
.filter {
  color: var(--Primary-Blue, #0067e2);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  text-transform: capitalize;
  margin-bottom: 0;
}
.btn {
  border: 1px solid var(--fill-grey-light8);
  border-radius: 8px;
  color: var(--text-light);
  display: block;
  font-size: 14px;
  font-weight: normal;
  height: auto;
  margin-right: 8px;
  padding: 10px 12px;
  white-space: nowrap;
  cursor: pointer;
}
.btnClick {
  background: var(--fill-sky-blue);
  color: var(--fill-primary-blue);
  font-weight: 600;
  padding-left: 28px;
  position: relative;
  border: none;
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: auto;
  margin-right: 8px;
  padding: 10px 12px;
  cursor: pointer;
}
.img-modal {
  .ant-modal-content {
    border-radius: 16px;
    padding: 0px !important;
    .ant-modal-body {
      padding: 0px !important;
    }
  }
  .ant-modal-header {
    border-bottom-color: white;
    padding-top: 24px;
    border-radius: 16px 16px 0px 0px;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
}
.requirementBox {
  border-radius: 8px;
  background: var(--Primary-Blue-2, #f2f8ff);
  display: flex;
  padding: 6px 8px;
  align-items: flex-start;
  gap: 4px;
  margin-left: 16px;
}
.requirementTxt {
  color: var(--Primary-Violet, #3944b4);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Nunito Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 85.714% */
  margin-bottom: 0px;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
}
.dispatch-background {
  border-radius: 32px;
  background: #ecfcfc;
  padding: 2px 6px;
  width: fit-content;
  margin-top: -5px;
}
.text-green {
  color: #27ae60;
}
.text-teal-green {
  color: #017180;
}
.wt-600{
  font-weight: 600;
}
.fabric-image-label{
    position: absolute;
    bottom: 0px;
    left: 0;
    color: #00142D;
    font-size: 12px;
    width: 100%;
    text-align: center;
    background: rgba(255, 255, 255, 0.60);
    font-weight: 600;
    white-space: nowrap /* Prevents text wrapping */;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 4px;
    padding: 0;
  }
.extra-top-padding{
  padding-top: 10px;
}
.left-chevron-image{
  padding-left: 4px;
}