.lfbid-box {
  display: flex;
  cursor: pointer;

  .id-value {
    color: grey;
    padding-left: 1rem;
    font-weight: 700;
    font-size: 20;

    .inner-id-value {
      color: grey;
      font-weight: 600;
    }
  }
}

.title-bold {
  color: var(--neutral-black, #00142d);
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.modal-header {
  margin-bottom: 36px;
  color: var(--neutral-gray-1, #303c4a);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modal-body {
  display: flex;
  width: 100%;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;

  .text {
    color: var(--neutral-gray-2, #626870);
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    .toggle-status {
      display: flex;
      width: 42px;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.detail-box {
  margin-top: 1.5rem;
  width: 42vw;
  height: fit-content;
  padding: 1rem 1.5rem 1rem 1.5rem;
  box-sizing: border-box;
  border: 0.5px solid #0067e2;
  border-radius: 1rem;
  background-color: #f2f8ff;
}

.scrollable {
  max-height: 210px;
  overflow-y: auto;
  width: 100%;
}

.scroll-identifier {
  width: 6px;
  height: 36px;
  background: #7b61ff;
  border-radius: 24px;
  margin: auto 12px;
}

.scrollable-box {
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
}

.box-field {
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 14px;
  color: grey;

  .field-name {
    margin-right: 2rem;
    min-width: max-content;
    display: flex;
    flex: 1;
  }

  .field-value {
    color: grey;
    text-align: right;
    width: fit-content;
    display: flex;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;

    .tick-icon {
      margin-left: 4px;

      img {
        margin-bottom: 2px;
      }
    }

    .tick-padding {
      padding-right: 15px;
    }
  }

  .field2-value {
    display: flex;
    justify-content: flex-end;
  }
}

.detailbox-inner {
  width: fit-content;
  flex-direction: column;
  display: flex;
}

.detailbox-outer {
  width: 100vw;
  padding: 10px;
  display: flex;
  gap: 2rem;
}

.lfbWithTick {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .tick-icon {
    margin-left: 4px;
    margin-bottom: 2px;
  }
}

.box-heading {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: black;
  padding-bottom: 8px;
}

.download-box {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  .pill-style {
    background-color: white;
  }
}

.remarks-field {
  text-align: justify !important;
  word-wrap: normal !important;
  word-break: normal !important;
}
.label {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  justify-content: space-between;
  display: flex;
}

.inputLabel {
  font-size: 1rem;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 42px;
}
.inputColor {
  height: 44px;
}
.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}
.label {
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
  justify-content: space-between;
  display: flex;
}
.addMoreBtn {
  display: flex;
  width: 120px;
  height: 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 0px solid var(--Primary-Blue, #0067e2);
  background: var(--Primary-Blue-2, #f2f8ff);
  margin-bottom: 24px;
  cursor: pointer;
}
.addMoreTxt {
  color: var(--Primary-Blue, #0067e2);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: 'Nunito Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.warningContainer {
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  background: var(--Primary-Yellow-3, #fffee5);
  align-items: center;
  margin-bottom: 16px;
}
.warningTxt {
  color: var(--Primary-Violet-2, #6e6cb2);
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.infoContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 24px;
  margin-top: 24px;
}
.infoMessage {
  color: #dd750e;
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.discountingTxt {
  color: var(--Neutral-Gray-2, #626870);
  font-family: 'Nunito Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}
.option {
  border-radius: 8px;
  border: 1px solid var(--Neutral-Gray-5, #e5e5e5);
  background: var(--Neutral-White, #fff);
  display: flex;
  height: 40px;
  padding: 3px 14px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.selected {
  border-radius: 8px;
  background: var(--Primary-Blue-2, #f2f8ff);
  border: 0px;
  color: #0067e2;
}

.heading {
  color: #00142d;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding-bottom: 8px;
}

.data-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0;
}

.data-row .data-label {
  width: 100%;
  color: #626870;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
}

.data-row .data-value {
  color: #00142d;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  text-align: right;
  width: 100%;
  display: flex;
  justify-content: end;
  gap: 2px;
}

.lfd-id-detail-container {
  height: 100vh;
  overflow-y: auto;
  background-color: white;
}

.header {
  width: fit-content;
  display: flex;
  gap: 2px;
  justify-content: baseline;

  margin: 30px 30px;
  margin-left: 5%;
}

.customerName-lfbId {
  margin-left: 16px;
  color: #00142d;
  font-size: 16px;
  font-weight: 700;
}

.international {
  color: #626870;
  font-size: 16px;
  font-weight: 700;
}

.container {
  margin-top: -20px;
  background-color: white;
  width: 100vw;
  display: flex;
  gap: 30px;
  justify-content: center;
}
.pill-container {
  display: flex;
  width: fit-content;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid lightblue;
  border-radius: 24px;
  cursor: pointer;

  .pill-name {
    color: rgb(0, 103, 226);;
    padding: 4px 4px 4px 8px;
    width: fit-content;
    max-width: 130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .pill-icon {
    padding: 6px 8px 6px 4px;
  }
}
.download-box {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  .pill-style {
    background-color: white;
  }
}
